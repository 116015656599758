/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins';
}
.react-icons {
    vertical-align: middle;
}
.topbarHeader {
    position: fixed;
    z-index: 400
}
.topbarSubHeader {
    position: fixed;
    top: 80px;
    z-index: 300
}
.topbarSubHeaderLevel2 {
    top: 120px;
    z-index: 300
}
.mainViewContainer {
    max-height: calc(100vh - 120px);
}
.mainViewContent2Headers {
    margin-top: 80px;
    padding-bottom: 15px;
}
.mainViewContent {
    margin-top: 50px;
    padding-bottom: 15px;
}
.bgImage {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.homeTitle {
    color: black;
    font-size: 30px;
    z-index: 110;
    /*
    height: 80vh;
    */
    background-color: rgba(255,255,255,0.9);
    border-color: white;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 10px;
    border-width: 1px;
    text-align: center;
    margin: 20px;
}
@media only screen and (max-width: 600px) {
    .homeTitle {
        z-index: 80;
        position: absolute;
        top: 150px;
    }
}
@media only screen and (min-width: 600px) {
    .homeTitle {
        position: absolute;
        top: 100px;
        margin-left: 40px;
        max-width: 40%;
    }
}
.homeDesc {
    color: black;
    font-size: 14px;
    margin-top: 20px;
}
.bgcontainer {
    /*
    position: fixed;
    top: -50%;
    left: -50%;
    width: 100vh;
    height: 100vh;
    position: fixed;
    height: 100vh;
    */
}
.bgcontainer img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
}
.submenuItem:hover {
    color: #ddd;
}
.rateCard {
    background-color: #FCFCFC;
    border-color: blue;
    border-width: 1px;
    padding: 10px;
    text-align: left;
    margin: 10px;
}
.newsCard {
    margin-top: 10px;
    background-color: #FCFCFC
}
.newsTitle {
    font-size: 18px;
}
.newsText {
    font-size: 12px;
}
.containerFullScreenCentered {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centered {
    text-align: center;
}
.text {
    font-size: 14px;
    color: black;
}

.busMapPin {
    position: absolute;
    z-index: 200;
    width: 50px;
    height: 50px;
    left: -25px;
    top: -50px
}
.busStopMapPin {
    position: absolute;
    z-index: 190;
    width: 20px;
    height: 33px;
    left: -10px;
    top: -33px
}

.markerInfo {
    position: absolute;
    top: 0;
    background-color: white;
    border-radius: 5px;
    z-index: 300;
    min-width: 100px;
    text-align: left;
    padding: 5px
}
.markerInfoClose {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: white;
    border-radius: 8px;
}
.markerInfoClose:hover {
    cursor: pointer;
}
.markerInfoTitle {
    font-weight: bold;
    font-size: 12px;
    color: white;
}
.markerInfoDesc {
    font-size: 12px;
    color: white;
}
.markerInfoPanel {
    font-size: 12px;
    margin-top: 5px;
    background-color: white;
}
.markerInfoPanelCircle {
    background-color: black;
    display: inline-flex;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    padding: 2px;
    font-size: 9px;
    text-align: center;
    margin-right: 3px;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.realtimeBanner {
    font-size: 11px;
    color: white;
    position: fixed;
    /*top: 159px;*/
    top: 119px;
    z-index: 300;
    width: 100%;
}
.realtimeBannerDot {
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 6px;
    margin-left: 5px;
    justify-content: center;
    display: inline-block;
}
.realtimeBannerDotHidden {
    background-color: transparent;
}
.mapContainer {
    /*height: calc(100vh - 160px);*/
    height: calc(100vh - 140px);
    width: 100%;
}
.lineBoxMini {
    display: inline-flex;
    align-items: center;
    color: white;
    font-size: 14px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
}
